<template>
    <v-dialog :value="value" max-width="600px" persistent @input="$emit('input', $event)">
      <v-card>
        <v-card-title>{{ formTitle }}</v-card-title>
        <v-card-text>
          <ValidationObserver ref="formObserver">
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <ValidationProvider name="Email" rules="required|email|max:128" v-slot="{ errors, valid }">
                      <v-text-field
                        v-model="searchEmail"
                        label="Search User by Email*"
                        :error-messages="hasInteracted && errors || []"
                        outlined
                        dense
                        :success="hasInteracted && valid"
                        :error="hasInteracted && errors.length > 0"
                        @input="handleInteraction"
                      />
                    </ValidationProvider>
                    <v-btn color="primary" class="mt-2" :loading="localIsLoading" @click="onSearch">
                      Search
                    </v-btn>
                  </v-col>
    
                  <v-col cols="12" v-if="foundUser">
                    <v-card class="elevation-1 mb-4">
                      <v-card-title>User Details</v-card-title>
                      <v-card-text>
                        <div><strong>Id:</strong> {{ foundUser.id }}</div>
                        <div><strong>Type:</strong> {{ foundUser.type || 'None' }}</div>
                        <div><strong>Company:</strong> {{ foundUser.companyId || 'None' }}</div>
                        <div><strong>Is Head Coach:</strong> {{ foundUser.isHeadCoach ? 'Yes' : 'No' }}</div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" v-if="!foundUser && searchAttempted && !localIsLoading">
                    <v-alert type="error" outlined dense>
                      User not found or is not of type "coach". Please check the email address and try again.
                    </v-alert>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </v-card-text>
    
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="onCancel">Cancel</v-btn>
          <v-btn
            text
            color="primary"
            :loading="localIsLoading"
            v-if="foundUser && !foundUser.companyId"
            @click="onAdd"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
  import useAcademies from '@/composables/api/academies';
  
  extend('required', (value) => !!value || 'This field is required.');
  extend('email', (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value) || 'Invalid email address.';
  });
  extend('max', (value, args) => {
    if (!value) return true;
    return value.length <= args.length || `Maximum length is ${args.length} characters.`;
  });
  
  export default {
    components: {
      ValidationObserver,
      ValidationProvider,
    },
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      item: {
        required: true,
        type: Object,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        searchEmail: '',
        foundUser: null,
        localIsLoading: false,
        hasInteracted: false,
        searchAttempted: false, 
      };
    },
    computed: {
      formTitle() {
        return 'Add User to Academy';
      },
    },
    methods: {
      handleInteraction() {
        this.hasInteracted = true;
      },
      async onSearch() {
        if (!this.searchEmail) {
          this.hasInteracted = true;
          return;
        }
  
        this.localIsLoading = true;
        this.foundUser = null;
        this.searchAttempted = true;
        const { searchUserByEmail } = useAcademies();
        try {
          const response = await searchUserByEmail(this.searchEmail);

          if (response && response.type === 'coach') {
            this.foundUser = response;
          } else {
            this.foundUser = null;
          }
        } catch (err) {
          console.error('Error occurred during search:', err);
        } finally {
          this.localIsLoading = false;
        }
      },
      onAdd() {
        this.$emit('add', this.foundUser);
      },
      onCancel() {
        this.$emit('cancel');
      },
    },
  };
  </script>
  