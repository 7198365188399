<template>
  <v-dialog :value="value" max-width="600px" persistent @input="$emit('input', $event)">
    <v-card>
      <v-card-title>{{ formTitle }}</v-card-title>
      <v-card-text>
        <ValidationObserver ref="formObserver">
          <v-form :disabled="isLoading">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider name="Name" rules="required|max:128">
                    <template v-slot="{ errors }">
                      <v-text-field v-model="item.name" label="Name*" :error-messages="errors" />
                    </template>
                  </ValidationProvider>
                </v-col>

                <v-col cols="12">
                  <ValidationProvider name="Street" rules="required|max:128">
                    <template v-slot="{ errors }">
                      <v-text-field v-model="item.address.street" label="Street*" :error-messages="errors" />
                    </template>
                  </ValidationProvider>
                </v-col>

                <v-col cols="6">
                  <ValidationProvider name="House No" rules="required|max:16">
                    <template v-slot="{ errors }">
                      <v-text-field v-model="item.address.houseNo" label="House No.*" :error-messages="errors" />
                    </template>
                  </ValidationProvider>
                </v-col>

                <v-col cols="6">
                  <ValidationProvider name="ZIP Code" rules="required|max:16">
                    <template v-slot="{ errors }">
                      <v-text-field v-model="item.address.zipCode" label="ZIP Code*" :error-messages="errors" />
                    </template>
                  </ValidationProvider>
                </v-col>

                <v-col cols="12">
                  <ValidationProvider name="City" rules="required|max:64">
                    <template v-slot="{ errors }">
                      <v-text-field v-model="item.address.city" label="City*" :error-messages="errors" />
                    </template>
                  </ValidationProvider>
                </v-col>

                <v-col cols="12">
                  <ValidationProvider name="Country" rules="required|max:64">
                    <template v-slot="{ errors }">
                      <v-text-field v-model="item.address.country" label="Country*" :error-messages="errors" />
                    </template>
                  </ValidationProvider>
                </v-col>

                <v-col cols="6">
                  <ValidationProvider name="Telephone" rules="required|regex:/^[+0-9 -]+$/">
                    <template v-slot="{ errors }">
                      <v-text-field v-model="item.telephone" label="Telephone*" :error-messages="errors" />
                    </template>
                  </ValidationProvider>
                </v-col>

                <v-col cols="6">
                  <ValidationProvider name="Fax" rules="regex:/^[+0-9 -]*$/">
                    <template v-slot="{ errors }">
                      <v-text-field v-model="item.fax" label="Fax" :error-messages="errors" />
                    </template>
                  </ValidationProvider>
                </v-col>

                <v-col cols="12">
                  <ValidationProvider name="Email" rules="required|email|max:128">
                    <template v-slot="{ errors }">
                      <v-text-field v-model="item.email" label="Email*" :error-messages="errors" />
                    </template>
                  </ValidationProvider>
                </v-col>

                <v-col cols="6">
                  <ValidationProvider name="Tax Number" rules="required|max:32">
                    <template v-slot="{ errors }">
                      <v-text-field v-model="item.taxNumber" label="Tax Number*" :error-messages="errors" />
                    </template>
                  </ValidationProvider>
                </v-col>

                <v-col cols="6">
                  <ValidationProvider name="Tax Number EU" rules="max:32">
                    <template v-slot="{ errors }">
                      <v-text-field v-model="item.taxNumberEu" label="Tax Number EU" :error-messages="errors" />
                    </template>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </ValidationObserver>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="onCancel">Cancel</v-btn>
        <v-btn text color="primary" :loading="isLoading" @click="onSave">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'

extend('required', value => !!value || 'This field is required.')
extend('max', (value, args) => {
  if (!value) return true
  return value.length <= args.length || `Maximum length is ${args.length} characters.`
})
extend('regex', (value, args) => {
  const regex = new RegExp(args.regex)
  return regex.test(value) || 'Invalid format.'
})
extend('email', value => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(value) || 'Invalid email address.'
})

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    item: {
      required: true,
      type: Object,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'save', 'cancel'],
  computed: {
    formTitle() {
      return this.item.id ? 'Edit Academy' : 'Add New Academy'
    },
  },
  methods: {
    async onSave() {
      const isValid = await this.$refs.formObserver.validate()
      if (isValid) {
        this.$emit('save', this.item)
      }
    },
    onCancel() {
      this.$emit('cancel')
    },
  },
}
</script>
