<template>
  <div>
    <v-card class="mb-4 elevation-1">
      <v-card-title>
        {{ academyName }}
        <v-spacer />
        <v-btn v-if="isAdmin" color="primary" dark @click="showEditAcademyDialog">
          Edit Academy
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <strong>Address:</strong>
            <div>{{ academyDetails.address?.street || 'N/A' }} {{ academyDetails.address?.houseNo || 'N/A' }}</div>
            <div>{{ academyDetails.address?.zipCode || 'N/A' }} {{ academyDetails.address?.city || 'N/A' }}</div>
            <div>{{ academyDetails.address?.country || 'N/A' }}</div>
          </v-col>
          <v-col cols="12" md="6">
            <strong>Contact:</strong>
            <div><strong>Telephone:</strong> {{ academyDetails.telephone || 'N/A' }}</div>
            <div><strong>Fax:</strong> {{ academyDetails.fax || 'N/A' }}</div>
            <div><strong>Email:</strong> {{ academyDetails.email || 'N/A' }}</div>
          </v-col>
          <v-col cols="12" md="6">
            <strong>Tax Information:</strong>
            <div><strong>Tax Number:</strong> {{ academyDetails.taxNumber || 'N/A' }}</div>
            <div><strong>EU Tax Number:</strong> {{ academyDetails.taxNumberEu || 'N/A' }}</div>
          </v-col>
          <v-col cols="12">
            <strong>Head Coach:</strong>
            <div>{{ headCoachEmail || 'None' }}</div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-text>
        <v-row justify="space-between">
          <v-col cols="auto">
            <v-btn v-if="isAdmin || isCoach" color="primary" dark @click="showAddUserDialog">
              Add New User
            </v-btn>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="filter"
              :append-icon="icons.mdiMagnify"
              label="Search Users"
              single-line
              hide-details
              dense
              outlined
              @input="filterUsers"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-data-table
        :headers="headers"
        :items="filteredUsers"
        :loading="isUsersLoading"
        :items-per-page="10"
        class="elevation-1"
      >
      <template #item.userType="{ item }">
        <v-chip
          outlined
          color="primary"
          class="ma-0"
        >
          {{ item.userType }}
        </v-chip>
      </template>

        <template #item.actions="{ item }">
          <div class="d-flex justify-end" style="max-width: 150px">
            <v-icon @click="removeUser(item)">
              {{ icons.mdiDeleteOutline }}
            </v-icon>
          </div>
        </template>
      </v-data-table>

      <academy-edit-user-dialog
        v-model="isEditUserDialogVisible"
        :editMode="editMode"
        :item="selectedUser"
        :isLoading="isLoading"
        @search="searchUserByEmail"
        @add="addUser"
        @save="saveUserChanges"
        @cancel="closeDialog"
      />

      <academy-edit-dialog
        v-model="isEditAcademyDialogVisible"
        :item="academyDetails"
        :is-loading="isLoading"
        @save="saveAcademyDetails"
        @cancel="closeEditAcademyDialog"
      />
    </v-card>
  </div>
</template>


<script>
import { mdiMagnify, mdiPencilOutline, mdiDeleteOutline } from '@mdi/js';
import { ref, computed, onMounted } from 'vue';
import useAcademies from '@/composables/api/academies';
import useAuth from '@/composables/useAuth';
import { useRouter } from '@/utils/index';
import AcademyEditUserDialog from './AcademyEditUserDialog.vue';
import AcademyEditDialog from '@/views/academies/partials/AcademyEditDialog.vue';

export default {
  components: {
    AcademyEditUserDialog,
    AcademyEditDialog,
  },
  setup() {
    const {
      updateUser,
      fetchAcademyUsers,
      fetchAcademyById,
      searchUserByEmail,
      users,
      isUsersLoading,
      updateAcademy,
    } = useAcademies();
    const { hasRole } = useAuth();
    const { route } = useRouter();

    const academyId = ref(route.value.params.id);
    const academyName = ref('');
    const academyDetails = ref({
      address: {
        street: '',
        houseNo: '',
        zipCode: '',
        city: '',
        country: '',
      },
      telephone: '',
      fax: '',
      email: '',
      taxNumber: '',
      taxNumberEu: '',
      billingId: '',
      description: '',
      location: '',
      photo: '',
      bulletPoints: [],
    });
    const filter = ref('');
    const filteredUsers = ref([]);
    const headCoachEmail = ref(null);
    const isEditUserDialogVisible = ref(false);
    const isEditAcademyDialogVisible = ref(false);
    const selectedUser = ref({});
    const editMode = ref(false);
    const isLoading = ref(false);
    const isAdmin = computed(() => hasRole(['ADMIN']));
    const isCoach = computed(() => hasRole(['COACH']));

    const headers = [
      { text: 'Email', value: 'email' },
      { text: 'User Type', value: 'userType' },
      { text: 'Actions', value: 'actions', sortable: false, align: 'end', width: '150px' },
    ];

    const filterUsers = () => {
      const search = filter.value.toLowerCase();
      filteredUsers.value = users.value.filter(user =>
        Object.values(user)
          .join(' ')
          .toLowerCase()
          .includes(search)
      );
    };

    const refreshUsers = async () => {
      try {
        await fetchAcademyUsers(academyId.value);
        filteredUsers.value = users.value;
        const headCoach = users.value.find(user => user.isHeadCoach);
        headCoachEmail.value = headCoach ? headCoach.email : null;
      } catch (error) {
        console.error('Failed to refresh users:', error);
      }
    };

    const fetchAcademyDetails = async () => {
      try {
        const academy = await fetchAcademyById(academyId.value);
        if (academy) {
          academyName.value = academy.name;
          academyDetails.value = academy;
        } else {
          console.error('Academy not found');
        }
      } catch (error) {
        console.error('Failed to fetch academy details:', error);
      }
    };

    const showAddUserDialog = () => {
      selectedUser.value = {};
      editMode.value = false;
      isEditUserDialogVisible.value = true;
    };

    const showEditAcademyDialog = () => {
      isEditAcademyDialogVisible.value = true;
    };

    const saveAcademyDetails = async updatedAcademy => {
      isLoading.value = true;
      try {
        await updateAcademy(updatedAcademy);
        await fetchAcademyDetails();
        isEditAcademyDialogVisible.value = false;
      } catch (error) {
        console.error('Failed to save academy details:', error);
      } finally {
        isLoading.value = false;
      }
    };

    const closeEditAcademyDialog = () => {
      isEditAcademyDialogVisible.value = false;
    };

    const editUser = user => {
      selectedUser.value = { ...user };
      editMode.value = true;
      isEditUserDialogVisible.value = true;
    };

    const addUser = async user => {
      try {
        const newUser = {
          userId: user.id,
          companyId: academyId.value,
          isHeadCoach: false,
        };

        const isHeadCoachExists = users.value.some(existingUser => existingUser.isHeadCoach);
        if (!isHeadCoachExists) {
          newUser.isHeadCoach = true;
        }

        isLoading.value = true;
        await updateUser(newUser);
        await refreshUsers();
        isEditUserDialogVisible.value = false;
      } catch (error) {
        console.error('Failed to add user to academy:', error);
      } finally {
        isLoading.value = false;
      }
    };

    const removeUser = async user => {
      try {
        const newUser = {
          userId: user.userId,
          companyId: null,
          isHeadCoach: false,
        };

        isLoading.value = true;
        await updateUser(newUser);
        await refreshUsers();
        isEditUserDialogVisible.value = false;
      } catch (error) {
        console.error('Failed to remove user from academy:', error);
      } finally {
        isLoading.value = false;
      }
    };

    const saveUserChanges = async user => {
      try {
        await updateUser(user);
        await refreshUsers();
        isEditUserDialogVisible.value = false;
      } catch (error) {
        console.error('Failed to save user changes:', error);
      }
    };

    const closeDialog = () => {
      isEditUserDialogVisible.value = false;
    };

    onMounted(async () => {
      await fetchAcademyDetails();
      await refreshUsers();
    });

    return {
      filter,
      filteredUsers,
      filterUsers,
      showAddUserDialog,
      showEditAcademyDialog,
      saveAcademyDetails,
      closeEditAcademyDialog,
      editUser,
      isEditUserDialogVisible,
      isEditAcademyDialogVisible,
      selectedUser,
      editMode,
      isLoading,
      headers,
      academyName,
      academyDetails,
      isAdmin,
      isCoach,
      icons: { mdiMagnify, mdiPencilOutline, mdiDeleteOutline },
      isUsersLoading,
      addUser,
      removeUser,
      saveUserChanges,
      closeDialog,
      headCoachEmail,
      searchUserByEmail
    };
  },
};
</script>
